import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { receiveLogin } from '../actions/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { getCurrentUserProfile, getUserProfileFields } from '../actions/userProfile';
import { useRouter } from 'next/router';

const UserProfileProvider = (props) => {
  const { children } = props;
  const user = useAuth0();
  const router = useRouter();
  const dispatch = useDispatch();
  const id_token = useSelector(state => state?.authStore?.id_token);
  const profile = useSelector(state => state?.userStore?.current);

  useEffect(() => {
    const func = async () => {
      if (user.isAuthenticated) {
        const claims = await user.getIdTokenClaims()
        dispatch(receiveLogin({ id_token: claims.__raw }));
      }

      if (id_token && !profile) {
        dispatch(getCurrentUserProfile(router));
        dispatch(getUserProfileFields());
      }
    }
    func();
  }, [user.isAuthenticated, id_token]);

  return id_token && !profile ? null : children;

};
export default UserProfileProvider;