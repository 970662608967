export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

const defaultState = {
};

const authReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        id_token: action.id_token
      }
    default:
      return state;
  }
};

export default authReducer;