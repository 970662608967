import { getClient } from '../lib/apiClient';

import { RECEIVE_CURRENT_PROFILE, RECEIVE_PROFILE_FIELDS } from '../reducers/user';

export function getCurrentUserProfile(router) {
  return async (dispatch, getState) => {
    const profile = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get('/api/v1/user_profiles/current');
    if (!profile.data?.roles?.includes('employee')) router.replace('/403')
    dispatch({
      type: RECEIVE_CURRENT_PROFILE,
      profile: profile.data,
    });
  };
}

export function getUserProfileFields() {
  return async (dispatch, getState) => {
    const fields = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get('api/v1/org_applications/getConsumerApp?includeDisabled=true')
    dispatch({
      type: RECEIVE_PROFILE_FIELDS,
      fields: fields.data.filter((fc) => fc.path.split('.')[0] === 'user_profile'),
    });
  }
}