import React, { useEffect } from 'react';
import '../styles/globals.css'
import { Auth0Provider } from '@auth0/auth0-react';

import { ThemeProvider, CssBaseline } from '@tmap/mmm-style-guide/src/Theming';
import { StyledEngineProvider } from '@tmap/mmm-style-guide/src/StyledEngineProvider';
import { LIGHT_THEME } from '../lib/themes';

import { wrapper } from '../store';
import UserProfileProvider from '../components/userProfileProvider';

import GlobalFonts from './_fonts';
import analytics from '../lib/analytics';


function MyApp(props) {
  const { Component, pageProps, router } = props;

  useEffect(() => {
    //Log pageview on transition to a new page without a window reload.
    const firePageUpdate = (url) => {
      analytics.page(url);
    }
    router.events.on('routeChangeComplete', firePageUpdate);
  }, []);

  const onRedirectCallback = (appState) => {
    analytics.track('login', {});
    router.push(appState && appState.returnTo ? appState.returnTo : '/')
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={LIGHT_THEME}>
        <CssBaseline />
        <GlobalFonts />
        <Auth0Provider domain='login.makemymove.com'
          clientId='QeEWkViICuH3tfr6ym4Vj6Z1Ius9m38C'
          redirectUri={(typeof window !== 'undefined' ? window.location.origin : 'http://localhost:3000') + '/callback'}
          onRedirectCallback={onRedirectCallback}>
          <UserProfileProvider>
            <Component {...pageProps} />
          </UserProfileProvider>
        </Auth0Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default wrapper.withRedux(MyApp);
