export const RECEIVE_CURRENT_PROFILE = 'RECEIVE_CURRENT_PROFILE';
export const RECEIVE_PROFILE_FIELDS = 'RECEIVE_PROFILE_FIELDS';

const defaultState = {
  current: null,
  fields: []
};

const userReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case RECEIVE_CURRENT_PROFILE:
      return {
        ...state,
        current: action.profile,
      }
    case RECEIVE_PROFILE_FIELDS:
      return {
        ...state,
        fields: action.fields,
      }
    default:
      return state;
  }
};
export default userReducer;